.save-analysis-view-layout {
  & .saved-analysis-title {
    margin: 2px auto;
    font-weight: 700;
    font-size: 20px;
    color: #505050;
    & > small {
      font-size: 12px;
      font-weight: 400;
      color: #00000066;
    }
  }

  & .new-analysis-placeholder {
    margin: 2px auto;
    color: lightgray;
    font-style: italic;
  }
  & .save-btn-container {
    margin: 5px auto;
  }
}
