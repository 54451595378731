.section-table-view {
    padding: 20px;

    table {
        border-spacing: 0;
        border: 1px solid black;
        border-collapse: collapse;

        tr {
            :last-child {
              td {
                border-bottom: 0;
              }
            }
        }

        th, td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
    
            :last-child {
                border-right: 0;
            }
        }
    }
}


#dynamic-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #dynamic-table td, #dynamic-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #dynamic-table tr:nth-child(even){background-color: #e4e4e4;}
  #dynamic-table tr:nth-child(odd){background-color: #fff;}
  
  #dynamic-table tr:hover {background-color: #ddd;}
  
  #dynamic-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
  }