#attribute-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}
  
#attribute-scroll::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}
  
#attribute-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b1b0b0;
}

#compare-studies-id {
    & tr {
        &.arms-main-heading,
        &.arm-treatment-row,
        &.arm-type-row,
        &.endpoint-values-main-heading,
        &.endpoint-name-arm-name-and-result-row,
        &.endpoint-name-hr-ci-p-value {
            background-color: #F0F0F0;
        }
        
        &.endpoint-name-arm-name-and-result-row {
            & .ant-table-cell {
                white-space: break-spaces;
            }
        }

        &.arm-name-row,
        &.endpoint-name-arm-result-row {
            background-color: #E3E3E3;
        }

        &.arm-name-row,
        &.arm-treatment-row,
        &.arm-type-row,
        &.endpoint-name-arm-name-and-result-row,
        &.endpoint-name-hr-ci-p-value {
            & .ant-table-cell:first-child > strong {
                font-size: 14px;
                white-space: break-spaces;
            }
        }
        &.arm-name-row,
        &.endpoint-name-arm-result-row {
            & .ant-table-cell {
                font-size: 14px;
                font-weight: 600;
                white-space: break-spaces;
            }
        }
        & button.ant-table-row-expand-icon-custom {
            float: right;
        }

        & .ant-table-cell p {
            margin-bottom: 0;
        }
    }

    & .ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover,
    & td.ant-table-column-sort {
        background: none;
    }

    & .ant-table-row-indent+.ant-table-row-expand-icon-custom {
        margin-top: 0px;
        margin-right: 0px;
        padding-bottom: 20px;
    }

    & .ant-table-row-expand-icon-custom {
        color: #1890ff;
        outline: none;
        cursor: pointer;
        transition: color .3s;
        position: relative;
        float: left;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        padding: 0;
        color: inherit;
        line-height: 17px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 2px;
        transform: scale(.94117647);
        transition: all .3s;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-shadow: none;
    }

}