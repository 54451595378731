.archived-table-details {
    margin-top: 12px;
    font-size: 16px;
    color: #2f2c3c;
    font-style: normal;
    font-family: "Open Sans";
}

.archived-table-breadcrumb {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #716E79;
}

.archived-studies-selected {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #2f2c3c;
    margin: 0;
    font-style: normal;
    font-family: "Open Sans";
    margin: 12px 0px;
}

.excluded-studies-title {
    font-weight: 700;
    font-size: 16px;
}

.archived-list-content {
    & .archive-breadcrumb {
        & .ant-breadcrumb-link {
            vertical-align: middle;
            & > a > svg {
                vertical-align: middle;
            }
        }
    }
}
.archived-list-table {
    /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f4f3f3;
    margin-left: 500px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #87848d;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}