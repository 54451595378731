.timeline-container{  
    // margin-right: 12px;
    // margin-left: 24px;
    margin-top: 12px;
    background: #FFFFFF;
    border: 1px solid #DEDCDE;
    }
    .line-chart-title{
        width: 28.33%;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #2F2C3C;
        margin-top: 4px;
    }
    .selection-container{
        display: flex;
        padding: 13px 12px 15px 12px;
    }
    .drug-selection-timeline {
        width: 41.33%; 
    }
    .viewBy-title{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #1A1628;
        flex: none;
        order: 0;
        flex-grow: 0;
        // margin-right: 5px;   
    }
    .viewby-trend-selection-container{
        width: 33.33%;
    }
    .expand-line-chart{
        float: right;
    }
    .viewby-trend-selection-container .ant-select-multiple .ant-select-selector {
        // height: 32px;
        overflow-y: auto;
    }
    .no-data {
        color: grey;
        font-size: 20px;
        padding: 18px;
        height: 250px;
        margin-top: 2%;
    }
.timeline-container .highcharts-input-group {
    display: none;
}
#timeline-chart-expand .highcharts-input-group {
    display: none;
}