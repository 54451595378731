#EIC_container .ant-modal-title {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: -0.15px !important;
}
.collaborator-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    letter-spacing: -0.2px;

    color: #2F2C3C;
}
.collaborator-confirm .ant-modal-title{
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150% !important;
}

.collaborator-confirm .ant-modal-header {
    border-bottom: 0;
}
.collaborator-confirm .ant-modal-body {
    padding: 0px 24px 10px 24px !important;
}