.privacy-policy-container {
  padding: 12px;
  left: -2px;
  top: 18px;
  border-radius: 2px;
  margin-top: 20px;
}

.privacy-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 15px;
}
.privacy-context {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 15px;
  /* or 20px */

  letter-spacing: -0.15px;
}
.privacy-policy-container .data-container .privacy-context > p > a {
  color: #27a6a4;
}
