#geo-map-id .ant-select-multiple .ant-select-selector {
  max-height: 60px;

  z-index: 1;
}

.no-data {
  color: grey;
  font-size: 20px;
  padding: 18px;
  height: 250px;
}

#geo-map-id .highcharts-container {
  width: 100% !important;
}
