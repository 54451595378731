@import "../../../assets/common-scss/CommonScss.scss";
#study-list-table-container {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f4f3f3;
    margin-left: 500px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #87848d;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

#study-list-table-container th {
  background: #ffffff;
}

#study-list-table-container .ant-table-column-has-sorters,
#study-list-table-container [rowspan="2"] {
  border-bottom: 1px solid #9c9aa1;
}

#study-list-table-container .ant-table-column-has-sorters {
  border-right: 1px solid #e7e3e3;
}

.ant-table-selection-column {
  border-left: none;
  border-right: none !important;
  padding-right: 0;
}

#study-list-table-container .ant-checkbox-checked .ant-checkbox-inner,
#study-list-table-container
  .ant-checkbox-indeterminate
  .ant-checkbox-inner:after {
  background: #27a6a4;
}

#study-list-table-container .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgb(192 236 235);
}

#study-list-table-container
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table {
  border-top: none;
}

#study-list-table-container
  .ant-table.ant-table-bordered
  > .ant-table-container {
  border-left: none;
}

#study-list-table-container h2 {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #454250;
  margin-right: 2rem;
  font-style: normal;
  font-family: "Open Sans";
}

#study-list-table-container
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td {
  border-right: 1px solid #e7e3e3;
  border-bottom: 1px solid #e7e3e3;
}

#study-list-table-container tr:first-child > th {
  text-align: center;
  white-space: pre-wrap;
}
ul.treatment-data{
  list-style-type: none;
  padding: 0;
  margin-bottom: 5px;
}
