.notification-container {
  padding: 70px 17px 0px 104px;
  background-color: #fff;
}
.notifications-header {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
}
.notification-legend {
  font-size: 12px;
  margin-right: 5px;
  span.anticon {
    svg {
      margin-right: 10px;
    }
  }
}
.notification-week-picker-row {
  margin-top: 10px;
}
.add-notification-modal {
  margin-left: 0px;
  margin-top: 0px;
  width: 300px;
}
.notification-study-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 11px;
  color: #27a6a4;
}
.notification-body {
  max-height: calc(100vh - 265px);
  margin-top: 30px;
  overflow-y: auto;
}
.notification-date {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 130%;
  color: #716e79;
}
.notification-tag {
  width: fit-content;
  background: #ecf8ff;
  border-radius: 24px;
  padding: 4px 12px;
  margin-top: 10px;
}
.notification-tag-text {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #0952ca;
}
.new-notification-text {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #000000;
  padding-left: 30px;
}
.new-notifications-row {
  margin-top: 15px;
}
.new-notifications-circle {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
  border-radius: 12px;
  background: #c3e0ff;
  text-align: center;
}
.new-notifications-circle-text {
  position: absolute;
  width: 14px;
  height: 16px;
  left: 5px;
  top: 6px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  color: #000000;
  text-align: center;
}
.notification-latest {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: right;
}
.descending-icon {
  transform: rotate(180deg);
}
.notification-body-content {
  margin-bottom: 15px;
}
.notification-modal {
  .ant-modal-body {
    height: 240px;
  }
}
