#main-container {
  padding-top: 82px;
  padding-left: 79px;
}
.search-heading {
  padding: 18px 0px 0px 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 133% !important;
  letter-spacing: 0.15px;
  color: #1a1628;
}
.search-container {
  position: fixed;
  /* top: 50%; */
  left: 50%;
  /* width: 30em; */
  /* height: 18em; */
  margin-top: 11em;
  margin-left: -18em;
}
.search-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 140% !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.15px;
}
.search-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.2px;
  justify-content: center;
  margin-top: -4px !important;
}
.home-container {
  padding-left: 20px;
  border-bottom: 1px solid #dedcde;
}
#home-breadcrumb {
  font-family: Open sans-serif;
  color: #716e79;
}
.search-container .ant-btn-primary {
  border-color: #27a6a4;
  background: #27a6a4;
}
.search-field {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 62px;
  padding-top: 8px;
}
.title {
  font-family: 'Open Sans' !important;
  font-size: 14px !important;
  color: #716E79 !important;
  cursor: default;
}
.search-field .ant-btn-primary {
  color: #fff !important;
}