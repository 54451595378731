.support-privacy-container {
  padding: 70px 17px 0px 104px;
  height: 995px;
  background-color: #fafafa;
}
.support-privacy-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black;
  font-weight: 700;
}
.download-manual-button {
  margin-right: 8px;
  z-index: 2;
}
.view-demo-button {
  margin-right: 8px;
  cursor: pointer;
  z-index: 2;
}
.support-page-buttons {
  float: right;
  margin-bottom: -36px;
}
