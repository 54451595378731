.support-form-container {
  padding: 16px;
  margin-top: 17px;
  height: 340px;
  left: -2px;
  top: 18px;
  background: #ffffff;
  border-radius: 2px;
}
.supprt-details {
  padding: 12px 122px 25px 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: #454250;
}
.support-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.2px;
  color: #1a1628;
}
.support-contact {
  display: grid;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: #454250;
}
.support-input-1 {
  display: inline-block;
  padding: 8px 9px 8px 0px;
}
.support-input {
  height: "42px";
  background: #fafafa;
  width: "321px";
}
.tt {
  width: 654px;
  background: #fafafa;
}
.form-action-buttons {
  padding: 14px 20px 0px 0px;
  float: right;
}
.form-button {
  margin: 8px;
  background: #f4f3f3;
  border: 1px solid #dedcde;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
  border-radius: 2px;
  color: #b2b0b6;
}
.input-style {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  color: #716e79;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: #fafafa;
  border: transparent;
}

.support-form-container .ant-form-item {
  margin: 0px 8px 8px 0px !important;
}
