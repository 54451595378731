@import "../../assets/common-scss/common-sidenavbar-style.scss";

#navbar-container {
  z-index: 5;
  & .ant-menu-item {
    height: auto;
    padding: 0;
    margin-bottom: 0;
    line-height: 36px;
    & .main-menu {
      padding-bottom: 8px;
      opacity: 0.4;
    }
  }

  & .ant-menu-item:hover,
  & .ant-menu-item-selected {
    background-color: #454250;

    & .main-menu {
      border-left: 2px solid #ee801a;
      border-right: 2px solid #454250;
      opacity: 1;
    }
  }
}

.user-profile-popover {
  & .ant-popover-inner-content {
    padding: 5px;
  }

  & span.logout-menu {
    cursor: pointer;
    &:hover {
      color: #27a6a4;
    }
  }
}
.evisense-logo {
  width: 80px;
  height: 80px;
  margin: -15px 0;
}
