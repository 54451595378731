#geo-map-id {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f4f3f3;
    margin-left: 500px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #87848d;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

#geo-map-id .drug-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #1a1628;
}

#custom-option-style .ant-select-item-option-state {
  order: 1;
  padding-right: 5px;
}
#custom-option-style .ant-select-item-option-content {
  order: 2;
}

#geo-map-id .studies-title {
  justify-content: space-between;
}

.custom-drop-down .ant-dropdown-menu-title-content {
  text-align: start;
}
