// .ant-btn:hover,
// .ant-btn:focus {
//   color: #fff !important;
// }

.add-to-storyboard-btn {
    background: #F8F8F8;
    border: 1px solid #27A6A4;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    border-radius: 2px;
    color: #27A6A4;
    & svg {
        vertical-align: middle;
    }
}