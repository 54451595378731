.demo-thumbnail {
  width: 240px;
  cursor: pointer;
}
.demo-video-card-title {
  border: none;
  border-radius: 4px;
  .ant-card-meta-title {
    font-size: 14px;
    font-weight: bold;
  }
  .ant-card-cover img {
    border-radius: 4px;
    border: 1px solid #c0c0c0;
  }
}
.demo-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 293px;
  height: 140px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
}
.demo-play-button {
  position: absolute;
  top: 50px;
  left: 0;
  opacity: 0.8;
  z-index: 1;
}
