.compare-studies-footer .ant-drawer-footer {
  text-align: end;
}

#compare-studies-id
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell:first-child {
  border-right: 0px solid #dedcde;
}

#compare-studies-id .ant-table-thead .ant-table-cell {
  border-bottom: 1px solid #dedcde;
}

#compare-studies-id .ant-table-filter-trigger,
#compare-studies-id .ant-table-column-title {
  flex: 0 0;
}

#compare-studies-id .ant-table-filter-column {
  justify-content: flex-start;
}

.compare-studies-footer .ant-drawer-body {
  padding: 0;
  margin: 24px;
}

.compare-studies-footer .ant-drawer-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

#discstyle {
  padding: 0;
  margin: 0;
}

#discstyle li {
  list-style-type: none;
}
#discstyle li:before {
  content: "\00b7";
  font-size: 20px;
  line-height: 10px;
  padding: 0.5rem;
}

#compare-studies-id .ant-table-tbody .ant-table-row .ant-table-cell {
  vertical-align: baseline;
}
