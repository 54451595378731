.storyboard-container {
  background-color: #f5f5f5;
  padding: 12px 18px 15px 12px;
}

.storyboard-content {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  background: white;
  border-radius: 2px;
  // height: 540px;
  margin-top: 8px;
  // height: 450px;
  // max-height: 450px;
  overflow-y: scroll;
}

.panel-class .ant-tabs-tab {
  // padding: 16px !important;
  // background-color: #f8f8f8 !important;
  width: 180px !important;
  height: 76px;
}

.action-buttons {
  margin-top: 2.5%;
  margin-left: 94.9%;
  position: absolute;
  display: flex;
}

.add-storyboard-one {
  position: absolute;
  top: 5%;
  right: 74%;
}

.add-storyboard-two {
  position: absolute;
  top: 5%;
  right: 56%;
}

#parent-tab-storyboard .ant-tabs-nav-list {
  margin-left: 0px !important;
}

#parent-tab-storyboard .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 10px;
}

#parent-tab-storyboard .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "Open Sans";
}

.show-more-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  color: #27a6a4;
  margin-left: 7px;
}

#storyboard-modal li span {
  text-align: initial;
}

.delete-modal-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}

.modal-body {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding: 25px 34px;
}

.button-container {
  text-align: center;
  height: 50px;
}

.delete-action {
  padding: 5.5px 10px;
  width: 100px;
  height: 40px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.delete-action :hover {
  padding: 5.5px 10px;
  width: 100px;
  height: 38px;
  margin-left: -11px;
  margin-top: -10px;
  background: #27a6a4;
  color: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.addStoryBoard {
  background: white !important;
}

.rename-error {
  color: red;
  padding: 16px 0px 0px 0px;
}

.storyboard-container #parent-tab-storyboard .ant-tabs-nav {
  width: 95%;
}

.no-text-storyboard {
  height: 486px;
  background-color: rgb(229, 229, 229);
  padding: 17px 17px 20px 20px;
}

#parent-tab-storyboard {

  & div.ant-tabs-tab {
    display: block;
    &:has(#parent-tab-storyboard-tab-new-section) {
      background-color: #929696 !important;
    }
  }

  & span.tab-title-ellipsis {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & span.storyboard-show-more>svg {
    vertical-align: middle;
  }
}
.timeout-confirm .ant-modal-confirm-title{
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.15px;
    color: #1A1628;
    padding: 0px 0px 5px 0px;
}
.timeout-confirm-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #2F2C3C;
}