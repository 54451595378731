.sidebar {
    position: absolute;
    top: 168px;
    left: 80px;
    bottom: 0px;
    width: 310px;
    transition: left 0.3s ease-in-out;
    background-color: #fff;
    z-index: 999;
    height: 100vh;
    -webkit-box-shadow: 1px 0px 5px -2px #888;
}

.sidebar-toggle {
    position: absolute;
    top: 168px;
    left: 80px;
    height: 100vh !important;
    width: 50px;
    transition: right 0.3s ease-out;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0 8px 6px -6px black;
    -webkit-box-shadow: 1px 0px 5px -2px #888;
}

.toggle-filter-pane-btn {
    float: right;
    cursor: pointer;
    background-color: #27a6a4;
    border: 0px;
}

.toggle-filter-icon {
    margin-left: 10px;
    text-align: justify;
    cursor: pointer;
}

.filter-pane-footer {
    position: relative;
    bottom: 10px;
    display: flex;
    height: 4rem;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    z-index: 99;
    margin: 0px 1rem;
}

.filter-pane-footer>div {
    flex: 1;
}

.filter-pane-apply-btn {
    background-color: #27a6a4;
    color: #fff;
}

.expand-contract-p {
    margin-left: 15px;
    color: #27a6a4;
    cursor: pointer;
}

.expand-contract-parent {
    margin-left: 0px;
    color: #27a6a4;
    cursor: pointer;
}

/////////////////////////////////////////////////
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #27a6a4;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #27a6a4;
}

#container-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#container-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
}

#container-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b1b0b0;
}

.adv-container-scroll {
    overflow-y: sticky !important;
    overflow-x: hidden;
    max-height: calc(80vh - 75px);
}

#collapse-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#collapse-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
}

#collapse-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b1b0b0;
}

.collapse-div {
    max-height: 160px;
    overflow-y: auto;
}

.badge-bg {
    background-color: #2b44c7;
}

.search-input-adv {
    width: 100%;
    margin-bottom: 10px;
}

.collapse-scroll-div {
    max-height: 275px;
    overflow-y: auto;
}
.collapse-scroll-div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.collapse-scroll-div::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

.collapse-scroll-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b1b0b0;
}

.b-0 {
    border: 0px;
}

.zs-teal {
    color: #27a6a4;
}

.recruitment-input {
    width: 250px;
}

.collapse-ant {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0px;
}

.duration-input {
    width: 75px;
}

.mt-10 {
    margin-top: 10px;
}

.info-icon {
    padding-left: 5px;
    cursor: pointer;
}

.ant-picker-year-panel .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
    display: none;
}

.ant-picker-year-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
    pointer-events: none;
}

.adv-container-scroll {
    & .ant-checkbox-wrapper+.ant-checkbox-wrapper {
        margin-left: 0px;
    }
    & .ml-15 {
        margin-left: 15px !important;
    }
    & .ml-30 {
        margin-left: 30px !important;
    }
    & .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
        display: block !important;
        background-color: #f5f5f5;
        -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        & > .rc-virtual-list-scrollbar-thumb {
            background-color: #b1b0b0 !important;
            -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        }
    }

    & .study-design-filter,
    & .treatment-filter {
        & div.ant-collapse-content-box {
            padding-right: 0px;
            padding-top: 0px !important;
        }
    }
}