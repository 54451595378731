.evidence-landscape-container{  
    // margin-right: 12px;
    // margin-left: 24px;
    margin-top: 12px;
    background: #FFFFFF;
    // border: 1px solid #DEDCDE;
}

#container-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#container-scroll::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

#container-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #b1b0b0;
}

.container-scroll-class {
	// max-height: 550px;
	max-height: calc(100vh - 100px);
	overflow-y: auto;
	overflow-x: auto;
	width: 100%;
}

.chart-container {
	border: 1px solid #DEDCDE;
	padding: 10px;
}

.mt-10 {
	margin-top: 10px;
}

.chart-label {
	font: 'Open Sans';
	font-weight: 700;
	font-size: 16px;
}

.dropdown-label {
	font: 'Open Sans';
	font-weight: 700;
	font-size: 14px;
}

.chart-modal-container {
	margin-left: 50px;
	// margin-top: 15px;
}

.chart-padding {
	padding: 20px;
}

.add-chart-btn {
	margin-bottom: 25px;
	margin-top: 20px;
}

.capitalize {
	text-transform: capitalize;
}

.selection-container .ant-select-multiple .ant-select-selector {
    max-height: 35px;
    // overflow-y: auto;
}

.selection-dropdown {
	width: 250px;
}

.mt-m20 {
	margin-top: -20px;
}

.viewby-tooltip{ 
	padding-left: 5px;
	cursor: pointer;
}

.w-300 {
	width: 300px;
}

.view-by-multiple {
	margin-left: -12px;
	margin-top: 10px;
	width: 300px;
}

.view-by-multiple-modal {
	margin-left: -12px;
	margin-top: -5px;
	width: 300px;
}

.view-by-filter {
	margin-left: 55px;
	width: 250px;
}

.breakdown-by {
	padding-left: 5px;
	cursor: pointer;
}

.breakdown-by-modal {
	margin-top: -5px;
	cursor: pointer;
}

.breakdown-by-child {
	margin-left: 117px;
	width: 250px;
}

.ml-5 {
	margin-left: 5px;
}

.mt-30 {
	margin-left: 115px;
	width: 250px;
}

.breakdown-by-modal {
	margin-top: 10px;
	width: 300px;
}

.no-data-evidence {
    color: grey;
    font-size: 20px;
    padding: 18px;
    height: 250px;
	margin-top: 4%;
}


//////////////////////////

.add-storyboard-modal {
	margin-left: 0px;
	margin-top: 0px;
	width: 300px;
}

.w-100 {
	width: 100%;
}
.breakdown-filter-container {
	padding-right: 15px;
	& .dropdown-label {
		text-align: right;
		padding-right: 4px;
	}
}

.interactive-filter-confirm {
    & .ant-modal-confirm-title {
        font-weight: 800;
    }

	& .ant-modal-body {
		padding: 20px;
	}
}