.image-table-wrapper {
    margin-right: 20px;
    background-color: #ECFEFF;
    border: 1px solid gray;
}

.ant-switch-checked {
    background-color: #27a6a4;
}

/* Custom Tooltip CSS*/
.custom-tooltip {
    position: relative;
    display: inline-block;
}

.custom-tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #000000d4;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 5px 0;
    position: absolute;
    z-index: 3000;
    bottom: 115%;
    left: 50%;
    margin-left: -60px;
    font-size: 12px;
}

.custom-tooltip:hover .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000000d4 transparent transparent transparent;
}

.custom-tooltip:hover .tooltip-text {
    visibility: visible;
}

// .highlight-text-content {
//     position: relative;
//     display: inline-block;

//     &[data-title]:hover::after {
//         content: attr(data-title);
//         position: absolute;
//         top: -100%;
//         left: 0;
//     }
// }