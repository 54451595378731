.Header {
  display: flex;
  padding: 7px;
  padding-right: 100%;
  position: fixed;
  margin-left: 79px;
  background-color: white;
  border-bottom: 1px solid rgb(162, 161, 161);
  z-index: 1000;
}

.Question-mark-icon {
  position: absolute;
  right: 300px;
  margin-top: 6px;
}

.Heading {
  margin-left: -7px;
}

.logout-icon {
  color: #27a6a4 !important;
  font-size: 20px;
  margin-left: 11px;
}

.dashboard-container .ant-spin-nested-loading > div > .ant-spin {
  top: 150px !important;
}

.header-layout {
  & .analysis-section {
    // width: 85%;
    width: calc(100vw - 245px);
    z-index: 1000;
    padding: 7px;
    position: fixed;
    height: 55px;
  }
}
.evisense-logo-header {
  position: relative;
}
