#clip-view-menu .ant-dropdown-menu-title-content {
    text-align: initial !important;
}
.clipview-date{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #B2B0B6;
}
.clipview-analysis{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
}
.clipview-details{
    padding: 15px 0px 11px 11px;
    line-height: 0;
}