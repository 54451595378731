button {
  border: 1px solid #27a6a4;
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  background: #27a6a4;
  color: #ffffff;
}

.common-ant-btn {
  background: #27a6a4;
  border: 1px solid #27a6a4;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #27a6a4;
  border-color: #27a6a4;
}

.ant-btn-primary {
  &:hover, &:focus {
    color: #ffffff;
  }
}

// Pagination button styling

.ant-pagination-item:hover,
.ant-pagination-item:hover a,
.ant-pagination-item-active,
.ant-pagination-item-active a,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #27a6a4;
  border-color: #27a6a4;
}

.ant-pagination-total-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #454250;
}

// checkbox styling

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #27a6a4;
}

//sorter styling

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #27a6a4;
}

//dropdown selection

.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background: rgba(39, 166, 164, 0.1);
}
