#demo-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  height: 560px;
  background: white;
}

#map-box {
  width: 80%;
  float: left;
}

#container {
  height: 500px;
}

#side-box {
  float: right;
  width: 16%;
  margin: 100px 1% 0;
  padding-left: 1%;
  border-left: 1px solid silver;
  display: none;
}

#info-box {
  margin-top: 10px;
}

.or-view-as {
  margin: 0.5em 0;
}

#up {
  line-height: 30px;
  height: 30px;
  max-width: 400px;
  margin: 0 auto;
}

#up a {
  cursor: pointer;
  padding-left: 40px;
}

#chkDataLabels {
  display: inline;
}

.selector {
  height: 40px;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.selector .prev-next {
  position: absolute;
  padding: 0 10px;
  font-size: 30px;
  line-height: 20px;
  background: white;
  font-weight: bold;
  color: #999;
  top: -2px;
  display: none;
  border: none;
}

.selector .custom-combobox {
  display: block;
  position: absolute;
  left: 40px;
  right: 65px;
}

.selector .custom-combobox .custom-combobox-input {
  position: absolute;
  font-size: 14px;
  color: silver;
  border-radius: 3px 0 0 3px;
  height: 32px;
  display: block;
  background: url("https://www.highcharts.com/samples/graphics/search.png") 5px
    8px no-repeat white;
  padding: 1px 5px 1px 30px;
  width: 100%;
  box-sizing: border-box;
}

.selector .custom-combobox .ui-autocomplete-input:focus {
  color: black;
}

.selector .custom-combobox .ui-autocomplete-input.valid {
  color: black;
}

.selector .custom-combobox-toggle {
  position: absolute;
  display: block;
  right: -32px;
  border-radius: 0 3px 3px 0;
  height: 32px;
  width: 32px;
}

.selector #btn-next-map {
  right: -12px;
}

.ui-autocomplete {
  max-height: 500px;
  overflow: auto;
}

.ui-autocomplete .option-header {
  font-style: italic;
  font-weight: bold;
  margin: 5px 0;
  font-size: 1.2em;
  color: gray;
}

.loading {
  margin-top: 10em;
  text-align: center;
  color: gray;
}

.ui-button-icon-only .ui-button-text {
  height: 26px;
  padding: 0 !important;
  background: white;
}

#info-box .button {
  border: none;
  border-radius: 3px;
  background: #a4edba;
  padding: 5px;
  color: black;
  text-decoration: none;
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  margin: 0 3px;
  line-height: 30px;
}

@media (max-width: 768px) {
  #demo-wrapper {
    width: auto;
    height: auto;
  }

  #map-box {
    width: auto;
    float: none;
  }

  #container {
    height: 310px;
  }

  #side-box {
    float: none;
    width: auto;
    margin-top: 0;
    border-left: none;
    border-top: 1px solid silver;
  }
}
.geo-tab-container {
  border: 1px solid #dedcde;
  // margin-left: 24px;
  margin-top: 12px;
}

.geo-tab-container h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2f2c3c;
  margin-left: 20px;
}
