.saved-analysis-container {
    & ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    & ::-webkit-scrollbar-track {
        background: #f4f3f3;
        margin-left: 300px;
    }

    & ::-webkit-scrollbar-thumb {
        background: #87848d;
        border-radius: 8px;
    }

    & ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    & .analysis-desc {
        color: #716E79;
        font-size: 14px;
    }

    & .analysis-name {
        &>a {
            color: #2D8B93;
            font-weight: 400;
        }

        margin-bottom: 5px;
    }

    & .item-updated-person {
        font-size: 14px;
        color: #716E79;
    }

    & .item-updated-time {
        margin-bottom: 5px;
    }

    & .page-title {
        font-size: 18px;
        font-weight: 700;
    }
}

.action-menu {

    &>span>svg,
    & span.show-more-text {
        vertical-align: middle;
    }
}

.analysis-delete-confirm {
    & .ant-modal-confirm-title {
        font-weight: 800;
    }
}

.collaborator-manage-modal {
    .ant-modal-title {
        font-weight: 700;
    }

    & .coming-soon-container {
        text-align: center;
        padding: 35px 0px;

        &>h2 {
            font-weight: 700;
            font-size: 42px;
        }

        &>h4 {
            font-size: 18px;
            font-weight: 700;
        }

        & .coming-soon-message {
            font-size: 18px;
        }
    }

}

.collaborator-share-modal {
    .share-modal-title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
    }
    .share-modal-subtitle {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #454250;
    }
    .share-modal-actions {
        padding: 15px 0px 0px 84px;
    }
    .share-modal-button {
        text-align: center;
        color: #FFFFFF;
        background: #27A6A4;
        box-shadow: 0px 2px 0px rgb(0 0 0 / 4%);
        border-radius: 2px;
        margin-top: 29px;
        margin-left: -21px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 16px;
    }
    .share-modal-text {
        letter-spacing: -0.2px;
        color: #1A1628;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
    }
     .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: #27A6A4 !important;
    }
    .ant-select-item .ant-select-item-option .ant-select-item-option-active .ant-select-item-option-selected {
        color: #27A6A4 !important;
    }
    .share-modal-footer {
        font-family: 'Open Sans';
        font-style: normal;
        color: #454250;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        padding: 0px 110px 0px 99px;
    }
    .share-storyboard-modal {
        margin-left: 0px;
        margin-top: 0px;
        width: 467px;
    }
    .share-modal-add {
        width: 287px;
    }
    .share-modal-user-access {
        max-height: 150px;
        overflow-y: auto;
        padding-top: 8px;
    }
    .access-title {
        font-size: 12px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        align-items: center;
        letter-spacing: -0.2px;
        color: #454250;
    }
    .share-modal-user-access .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
    }
    .text-style {
        text-align: left;
    }
}

.collaborator-share-modal .ant-modal-content .ant-modal-footer{
    border-top: 0;
    border-radius: 0;
}
