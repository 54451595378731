.full-text-modal #content-scroll {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f4f3f3;
    }

    &::-webkit-scrollbar-thumb {
        background: #87848d;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.data-source {
    color: #7B7B7B;
    float: right;
    font-size: 14px;
}

.page-title-list-container {
    margin-top: 10px;
    max-height: 46px;
    overflow: auto;
}