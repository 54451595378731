@import url(../../../assets/common-scss/CommonScss.scss);

.studies-selected {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #2f2c3c;
  margin: 0;
  font-style: normal;
  font-family: "Open Sans";
}