li {
  span {
    text-align: center;
    div {
      text-align: center;
    }
    div {
      color: #ffffff;
      font-size: 12px;
      span {
        display: block;
        line-height: 1.5;
      }
    }
  }
}
