.support-faq-container {
  padding: 12px;
  left: -2px;
  top: 18px;
  background: #ffffff;
  border-radius: 2px;
  margin-top: 20px;
}
.support-faq-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.2px;
  color: #1a1628;
}
.faq-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: #1a1628;
}
.fqa-container .faq-heading .faq-answers {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: #454250;
  margin-bottom: 0;
  & p {
    margin-bottom: 0;
  }
  & mark {
    padding: 0;
  }
}
.fqa-container {
  overflow-y: scroll;
  max-height: 550px;
  img[class^="faq-screen"] {
    background-repeat: round;
  }
}
.faq-screen_1 {
  background-image: url("../../../assets//images/FAQScreen.png");
  height: 230px;
  width: 550px;
}
.faq-screen_2 {
  background-image: url("../../../assets//images/FAQScreen2.png");
  height: 230px;
  width: 550px;
}
.faq-screen_3 {
  background-image: url("../../../assets//images/FAQScreen3.png");
  height: 230px;
  width: 489px;
}
.faq-screen_4 {
  background-image: url("../../../assets//images/FAQScreen4.png");
  height: 250px;
  width: 493px;
}
.faq-screen_5 {
  background-image: url("../../../assets//images/FAQScreen5.png");
  height: 230px;
  width: 489px;
}
.faq-screen_6 {
  background-image: url("../../../assets//images/FAQScreen6.png");
  height: 244px;
  width: 484px;
}
.faq-screen_7 {
  background-image: url("../../../assets//images/FAQScreen7.png");
  height: 230px;
  width: 493px;
}
.faq-screen_8 {
  background-image: url("../../../assets//images/FAQScreen8.png");
  height: 230px;
  width: 469px;
}
.faq-screen_9 {
  background-image: url("../../../assets//images/FAQScreen9.png");
  height: 230px;
  width: 469px;
}
.faq-screen_10 {
  background-image: url("../../../assets//images/FAQScreen10.png");
  height: 230px;
  width: 469px;
}
.faq-screen_11 {
  background-image: url("../../../assets//images/FAQScreen11.png");
  height: 230px;
  width: 483px;
}
.faq-screen_12 {
  background-image: url("../../../assets//images/FAQScreen12.png");
  height: 230px;
  width: 489px;
}
.faq-screen_13 {
  background-image: url("../../../assets//images/FAQScreen13.png");
  height: 230px;
  width: 469px;
}
.faq-screen_14 {
  background-image: url("../../../assets//images/FAQScreen14.png");
  height: 230px;
  width: 469px;
}
.faq-screen_15 {
  background-image: url("../../../assets//images/FAQScreen15.png");
  height: 567px;
  width: 407px;
}
.faq-screen_16 {
  background-image: url("../../../assets//images/FAQScreen16.png");
  height: 560px;
  width: 407px;
}
.support-privacy-container {
  & .ant-tabs-tab:hover {
    color: #27a6a4;
  }
}
