@import "../../assets/common-scss/CommonScss.scss";

// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//   color: #27a6a4;
//   text-shadow: 0 0 0.25px #27a6a4;
// }

.ant-tabs-card {
  .ant-tabs-tab {
    font-weight: bold;
    min-width: 200px;
    height: 75px;
    align-items: flex-start;
    font-size: 14px !important;
    color: white;
    background-color: #27a6a491 !important;
  
    &:hover,
    &.ant-tabs-tab-active {
      background-color: white !important;
      & .ant-tabs-tab-btn {
        color: black;
        text-shadow: 0 0 0.25px black;
      }
    }
  }
}

.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav {
  margin: 0px !important;
}

.ant-tabs-ink-bar {
  height: 7px;
  background: #27a6a4;
}

.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus {
  color: #27a6a4;
}

.closed {
  padding-left: 131px;
  padding-top: 28px;
}

.open {
  margin-top: 30px;
  margin-left: 400px;
}

// Search & Filter Section styling
.search-input {
  // width: 350px;
  width: 100%;
}

.search-result {

  // margin-left: 30px;
  // margin-top: 5px;
  & .result-text {
    float: right;
    font-size: 12px;
  }
}

.mt-10 {
  margin-top: 10px;
}

.h-200 {
  height: 200px;
}

#parent-tab .ant-tabs-nav-list {
  width: 100%;
  margin-left: 20px;
}

#parent-tab .ant-tabs-extra-content {
  padding-right: 1rem;
}

// .open .ant-btn-primary[disabled],
// .closed .ant-btn-primary[disabled] {
//   background: #27a6a4;
//   border: 1px solid #27a6a4;
// }