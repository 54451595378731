.create-new-analysis-modal {
    & .heading {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    & .ant-form-item-label>label {
        font-size: 14px;
        font-weight: 700;
    }

    & .ant-modal-title {
        font-weight: 700;
    }

    & .desc-input {
        max-height: 120px;
        min-height: 120px;
    }
}

.ant-input:hover {
    border-color: #27a6a4;
}

.ant-input-focused, .ant-input:focus {
    border-color: #27a6a4;
    box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #27a6a4;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #27a6a4;
    box-shadow: none;
}