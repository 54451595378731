.add-comment {
    // background: #F8F8F8;
    // border: 1px solid #27A6A4;
    // box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    // border-radius: 2px;
    // color: #27A6A4;
    // width: 150px;
    // height: 40px;
    background: #FFFFFF;
    border: 1px solid #DEDCDE;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    border-radius: 2px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    & svg {
        vertical-align: middle;
    }
    margin-right: 10px;
}
.no-storyboard-class {
    box-sizing: border-box;
    position: inherit;
    width: 408px;
    height: 286px;
    left: 17px;
    background: #F8F8F8;
    border: 1px dashed #CCCCCC;
}
.no-storyboard-text {
    display: flex;
    padding: 66px;
    top: 69px;
    position: absolute;
    color: #87848D;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}
.last-updated-details{
    padding-top: 20px;
    margin-left: 11px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: #B2B0B6;
}
.edit-storyboard {
    // width: 85px;
    // height: 40px;
    background: #FFFFFF;
    border: 1px solid #DEDCDE;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    border-radius: 2px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.edit-storyboard-tooltip {
    border-radius: 2px;
}
.lock-icon {
    top: 8px;
    left: 91px;
    position: absolute;
}
.texbox-padding {
    padding: 20px 0px 20px 38px;    //reference style={{ padding: `20px 0px 20px ${isOwner || !isStoryboardLocked ? !isOwner && !isStoryboardLocked ? '38px' : '16px' : '38px'}`}}
}
.texbox-padding-1 {
    padding: 20px 0px 20px 16px; 
}
