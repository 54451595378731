.search-field {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;
}

.ant-btn-primary {
  background-color: #27a6a4;
  border-color: #27a6a4;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background-color: #27a6a4;
  border-color: #27a6a4;
}
.searched-item {
  font-family: "Open Sans" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #716e79 !important;
  cursor: default;
}
.title-home {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  color: #716e79 !important;
  cursor: pointer;
}
.main-container-search {
  padding-top: 60px;
  padding-left: 80px;
}

.ant-tabs-tab:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #27a6a4;
  & .ant-badge {
    color: #27a6a4;  
  }
}